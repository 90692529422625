import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, ConfigProvider, Flex, Form, Input, Row, Spin, Switch, Tooltip, Typography, message } from "antd";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CurrencyInput from 'react-currency-input-field';
import { useLazyGetUserQuery, useUpdateUserMutation } from "../services/admin/adminAuth";
import { useForm, Controller } from "react-hook-form";
import "../styles/UpdateUser.scss";
import ChangePassword from "./ChangePassword";
import ImageUploader from './ImageUploader';
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import AddTags from "./AddTags";
import SelectTags from "./SelectTags";
import { Tag } from "../types.ts";
import { RootState } from "../store";
import { setUserDetails } from "../slices/admin/adminUserSlice";
const { Text } = Typography;

interface UpdateUserFormValues {
  is_active: boolean;
  is_superuser: boolean;
  scope: string;
  in_leaderboard: boolean;
  id: number;
  full_name: string;
  email: string;
  primary_image_url: string;
  raise_goal: number;
  blurb: string;
  tags?: Tag[]
}

const UpdateUser: React.FC = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<UpdateUserFormValues>();
   const [getUser, { data: user, isLoading: userLoading, isError: userError }] = useLazyGetUserQuery();
  const [updateUser, { isLoading: updateLoading, isError: updateError, error }] = useUpdateUserMutation();
  const [selectedTags, setSelectedTags] = useState<{ [key: number]: number[] }>({}); 
  const [isEditing, setIsEditing] = useState(false);
  const userData = useSelector((state: RootState) => state.adminUser.user);

  // useEffect(() => {
  //   getUser();
  // }, []);

  useEffect(() => {
    if (userData) {
      setValue("full_name", userData?.full_name ?? '');
      setValue("email", userData?.email??'');
      setValue("primary_image_url", userData.primary_image_url??'');
      setValue("raise_goal", userData.raise_goal??0);
      setValue("blurb", userData.blurb??'');
      setValue("in_leaderboard", userData.in_leaderboard??false);
      //@ts-ignore
      if (userData?.tags) {
        const initialSelectedTags = userData.tags.reduce((acc, tag) => {
          // If the group_id already exists in the accumulator, add the tag id
          if (acc[tag?.group_id]) {
            acc[tag.group_id].push(tag.id);
          } else {
            // If the group_id doesn't exist, initialize it with an array containing the tag id
            acc[tag.group_id] = [tag.id];
          }
          return acc;
        }, {} as { [key: number]: number[] });
  
        // Set the selected tags state
        setSelectedTags(initialSelectedTags);
      }
      
    }
  }, [userData, setValue]);
console.log(selectedTags)
  const onSubmit = async (data: UpdateUserFormValues) => {
    try {
      // Flatten selectedTags object into a single array of tag IDs
      const flattenedTags = Object.values(selectedTags).flat();
  
      await updateUser({ ...data, tag_ids: flattenedTags }).unwrap();
      await getUser();
      dispatch(
        setUserDetails(user??{})
      );
      message.success('User updated successfully!');
    } catch (err) {
      console.error('Failed to update user:', err);
      message.error('Failed to update user. Please try again.');
    }
  };

  const handleTagSelection = (groupId: number, tagId: number, checked: boolean, oneTag: boolean) => {
    setSelectedTags((prevState) => {
      const groupSelectedTags = prevState[groupId] || [];

      if (oneTag && checked && groupSelectedTags.length > 0) {
        // If only one tag is allowed and a tag is already selected, show warning
        message.warning(`You can only select one tag for this group.`);
        return prevState; // Do not update state
      }

      return {
        ...prevState,
        [groupId]: checked
          ? [...groupSelectedTags, tagId] // Add tag
          : groupSelectedTags.filter((id) => id !== tagId), // Remove tag
      };
    });
  };

  if (userLoading) return <Spin />;
  if (userError) return <Text type="danger">Failed to fetch user data.</Text>;
  const handleProfileImageUpload = async(imageUrl: string) => {
    setValue('primary_image_url', imageUrl, { shouldValidate: true });
    if(userData){
      await updateUser({
        ...userData,
        primary_image_url: imageUrl,
        raise_goal: userData.raise_goal ?? 0,
        full_name: userData.full_name??'',
        scope: userData.scope??'',
        in_leaderboard: userData.in_leaderboard??false,
        id: userData?.id??0,
        blurb: userData.blurb ??'',
        is_superuser: userData.is_superuser?? false,
        is_active: userData.is_active ?? false, 
        email: userData.email ?? "", // Ensuring email is always a string
      }).unwrap();
    }
   
  };

  return (
    <> 
    

    

    <div className="edit-campaign-container">
    <Title level={2} className="editProduct">Edit Profile</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="edit-campaign-form">
      <Row>
      <Col sm={{span:5,push:0}} xs={{span:24,push:7}} style={{
        alignSelf:"auto"
      }} >
          <div className="profile-image-wrapper">
          <Controller
                name="primary_image_url"
                control={control}
               
                render={({ field }) => (
                  <>
                  {field.value && (
                      <img src={field.value} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                    )}
                    {/* <div
                      className="edit-icon-overlay"
                      onClick={() => setIsEditing(true)}
                      style={{ cursor: 'pointer', position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%', padding: '5px' }}
                    >
                        <EditOutlined style={{ color: '#fff' }} />
                        </div> */}
                        {(
                      <ImageUploader
                      icon={ true}
                        onUpload={handleProfileImageUpload}
                        width={512}
                        height={512}
                      />
                    )}
                    </>
                    )}
                    />
                    {errors.primary_image_url && <Text type="danger">{errors.primary_image_url.message}</Text>}
          </div>
        </Col>
           <Col flex={5}>
           <div >
           <Flex justify="space-between" align="center">

           <Form.Item
          label="Full Name"
          validateStatus={errors.full_name ? 'error' : ''}
          help={errors.full_name ? errors.full_name.message : ''}
          style={{
            width: "47%"
          }}
        >
              <Controller
                name="full_name"
                control={control}
                rules={{ required: "Full Name is required" }}
                render={({ field }) => (
                  <Input {...field} placeholder="Full Name" />
                )}
              />
            </Form.Item>

            <Form.Item
          label="Email"
          validateStatus={errors.email ? 'error' : ''}
          help={errors.email ? errors.email.message : ''}
          style={{
            width: "47%"
          }}
        >
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address"
                  }
                }}
                render={({ field }) => (
                  <Input {...field} placeholder="Email" />
                )}
              />
            </Form.Item>

            </Flex>
        
            <Form.Item
          label="Raise Goal"
          validateStatus={errors.raise_goal ? 'error' : ''}
          help={errors.raise_goal ? errors.raise_goal.message : ''}
        >
              <Controller
                name="raise_goal"
                control={control}
                rules={{ required: "Raise Goal is required" }}
                render={({ field }) => (
                  <CurrencyInput
                    className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInput'
                    placeholder="Please enter Goal"
                    value={field.value / 100}
                    decimalsLimit={2}
                    onValueChange={(value) => {
                      const centsValue = value ? parseFloat(value) * 100 : 0;
                      field.onChange(centsValue);
                    }}
                    prefix="$"
                  />
                )}
              />
            </Form.Item>
            <Form.Item
         label={
          <>
            Tags
            <Tooltip title="Select what tags you'd like your sales to count towards.">
              <   QuestionCircleOutlined style={{ marginLeft: 8, color: '#888' }} />
            </Tooltip>
          </>
        }
         
        >
          {
            userData?.associated_groups?.map((group)=>
           
          { 
            if(group.tags.length===0)return
            return(
              <>
              <label style={{marginTop:15,marginBottom:15,display:"block"}}>{group.name} </label>
              <SelectTags tagsData={
              group?.tags ??[]
            } 
            selectedTags={selectedTags[group.id] || []}
              setSelectedTags={(tagId, checked) => handleTagSelection(group.id, tagId, checked, group.one_tag)}
            />
              </>
            )}
            )
          }
           
            </Form.Item>

            <Form.Item
          label="Fundraising Message"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
              <Controller
                name="blurb"
                control={control}
                render={({ field }) => (
                  <TextArea
                  {...field}
      showCount
      maxLength={800}
     rows={8}
      placeholder="Blurb"
    />
                )}
              />
            </Form.Item>

            

            {updateError && (
              <div className="form-item full-width">
                <Text type="danger">{error ? error.toString() : 'Failed to update user. Please try again.'}</Text>
              </div>
            )}

<Row  justify={{
  xs: "center",
  sm:"end"
}}  >
              <Button type="primary" htmlType="submit" loading={updateLoading} className="red-button" style={{
                width: 206
              }}>
                Save
              </Button>
            </Row>
          </div>
           </Col>
         
        </Row>
      </Form>
    </div>
    <ChangePassword/>
    </>
    
 
  );

};

export default UpdateUser;
