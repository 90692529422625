import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { UserResponse } from '../../types.ts';
import { Middleware } from '@reduxjs/toolkit';
import { isRejected, isRejectedWithValue } from "@reduxjs/toolkit";
import { signOut } from '../../slices/admin/adminUserSlice';

interface SignInRequest {
  email: string;
  password: string;
}

interface SignInResponse {
  data?: 
  {
    detail?: string;
  };
  access_token: string;
}

interface ResetPasswordRequest {
  token: string;
  password: string;
}
interface updatePasswordRequest {
  currentPassword: string;
  password: string;
  token: string;
}
interface SignUpData {
  email: string;
  password: string;
  full_name: string;
  group_id: number;
}

interface QueryActionArg {
  type: "query" | "mutation";
}

interface QueryActionPayload {
  status: 400 | 401 | 404 | 200 | 500 | 403;
}
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const rtkQueryErrorLogger: Middleware =
  (store) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejected(action) || isRejectedWithValue(action)) {
      const requestType = (action.meta.arg as QueryActionArg).type;
      if ((action?.payload as QueryActionPayload)?.status === 403) {
        
        // Prevent redirect if already on /signin
        store.dispatch(signOut());
        // adminAuthApi.util.resetApiState();
        if (window.location.pathname !== '/signin') {
          window.location.href = '/signin';
        }
      } 
    }

    return next(action);
  };

export const adminAuthApi = createApi({
  reducerPath: 'adminAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).adminUser.token;
      headers.set('ngrok-skip-browser-warning', `true`);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    
  }),
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInRequest>({
      query: (credentials) => ({
        url: 'auth/login/access-token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          username: credentials.email,
          password: credentials.password,
        }).toString(),
      }),
    }),
    signUp: builder.mutation<SignInResponse, SignUpData>({
      query: (credentials) => ({
        url: 'users/signup',
        method: 'POST',
        body: credentials
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `auth/password-recovery/${email}`,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: ({ token, password }) => ({
        url: `auth/reset-password`,
        method: 'POST',
        body: { token, new_password: password },
      }),
    }),
    updatePassword: builder.mutation<void, updatePasswordRequest>({
      query: ({ currentPassword, password,token }) => ({
        url: `users/me/password`,
        method: 'PATCH',
        body: {current_password:currentPassword, new_password: password },
      }),
    }),
    getUser: builder.query<UserResponse, void>({
      query: () => ({
        url: 'users/me',
        method: 'GET',
      }),
    }),
    updateUser: builder.mutation<void, UserResponse>({
      query: (data) => ({
        url: `users/me`,
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const { useSignInMutation, useForgotPasswordMutation, useResetPasswordMutation,useUpdatePasswordMutation, useLazyGetUserQuery, useSignUpMutation, useUpdateUserMutation } = adminAuthApi;
