import React, { useState, useEffect } from 'react';
import '../styles/Banner.scss';
import { useParams } from 'react-router-dom';
import { useGetCampaignForStudentQuery, useGetCampaignForUserQuery, useGetLeaderboardForPublicCampaignBasedOnTagsQuery } from '../services/campaign';
import { convertUTCDateToLocalDate, formatWithCommas } from '../utils';
import Loader from './Loader';
import OrderSuccess from './OrderSuccess';
import { PlayCircleOutlined } from '@ant-design/icons';
import PublicLeaderBoard from './PublicLeaderBoard'; // Import the leaderboard componen

const Banner: React.FC = () => {
  const { id, campaign_id, user_id } = useParams<{ id: string, campaign_id: string, user_id: string }>();
  const { data, error, isLoading, isError } = useGetCampaignForUserQuery(campaign_id || '0', { skip: !campaign_id });
  const { data: userData, error: userError, isLoading: userIsLoading } = useGetCampaignForStudentQuery({ userId: user_id || '0', campaignId: campaign_id ?? '0' }, { skip: !user_id || !campaign_id });
  
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 , status: 'not_started'});
  const [isCampaignEnded, setIsCampaignEnded] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isLeaderBoardVisible, setIsLeaderBoardVisible] = useState(false); // Sta
  const { data: studentData = [], error: errorFetchingTagLeaderBoard, isLoading: isLoadingStudents ,isFetching} = useGetLeaderboardForPublicCampaignBasedOnTagsQuery({
    campaignId: data?.id ?? '0',
    groupId: data?.group_id ?? 0,
    skip: 0,
    limit: 10000,
  },{skip:!data?.id||!data?.group_id});
  const calculateTimeLeft = () => {
    if (!data?.end_date) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0, status:'not_started' };
    }
  
    const startDate = convertUTCDateToLocalDate(new Date(data.start_date) as Date);
    const endDate = convertUTCDateToLocalDate(new Date(data.end_date) as Date);
    const now = new Date();
  console.log(now.getTime() , startDate.getTime())
    if (now.getTime() < startDate.getTime()) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0, status: 'not_started' };
    }
  
    const difference = endDate.getTime() - now.getTime();
  
    if (difference <= 0) {
      setIsCampaignEnded(true);
      return { days: 0, hours: 0, minutes: 0, seconds: 0, status: 'ended' };
    }
  
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
      status: 'active',
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [data]);

  const padTime = (time: number) => time.toString().padStart(2, '0');

  if (isLoading) return <Loader />;
  if (data?.is_closed) {
    return <OrderSuccess closed={true} />
  }
  if (isError) return <></>;

  const progressPercentage = data ? (data.money_raised / data.money_goal) * 100 : 0;

  const videoId = data?.video_url?.split('v=')[1]?.split('&')[0];
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  const handlePlayButtonClick = () => {
    setIsVideoPlaying(true);
  };

  const handleLeaderBoardOpen = () => {
    setIsLeaderBoardVisible(true); // Open the leaderboard modal
  };

  const handleLeaderBoardClose = () => {
    setIsLeaderBoardVisible(false); // Close the leaderboard modal
  };

  return (
    <>
    <section className="banner">
      <div className="school-logo">
        <img src={data?.logo_image_url} alt="School Logo" className="logo" />
      </div>
      <h1 className="main-heading" style={{
        color: data?.title_color
      }}>{data?.title}</h1>
      
      <div className="fundraising-image">
        <img src={data?.hero_image_url} alt="We are Raising Funds" className="fundraising-banner" />
      </div>
      <p className="description" style={{
        color: data?.blurb_color
      }}>{data?.blurb}</p>
      {videoId && (
        <div className='video-container'>
          {!setIsVideoPlaying ? (
            <div className="video-thumbnail" onClick={handlePlayButtonClick}>
              <img src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt="Video Thumbnail" className="video-thumbnail-image" />
              <div className="play-button-overlay">
                <PlayCircleOutlined className="play-button-icon" />
              </div>
            </div>
          ) : (
            <iframe
              className="video-iframe"
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
            />
          )}
        </div>
      )}
      <div className="countdown-timer">
      {timeLeft.status === 'not_started' ? (
    "The campaign has not begun."
  ) : timeLeft.status === 'ended' ? (
    "The campaign has ended."
  ) : (
    <>
      {timeLeft.days > 0 && (
        <span>{timeLeft.days} {timeLeft.days === 1 ? "Day" : "Days"} </span>
      )}
      {padTime(timeLeft.hours)}:{padTime(timeLeft.minutes)}:{padTime(timeLeft.seconds)} Remaining!
    </>
  )}
      </div>
      {((  !isCampaignEnded)||(timeLeft.status !== 'not_started')) && (
        <>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
          </div>
          <div className="raised-amount">
            Raised: ${formatWithCommas(data?.money_raised ??0)} / ${formatWithCommas(data?.money_goal)}
          </div>
          
        </>
      )}
      {data?.leader_board_enabled&&
          <a onClick={handleLeaderBoardOpen} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
            View Leaderboard
          </a>
          }
      
     
        
      
    </section>
    {isLeaderBoardVisible&&
      <PublicLeaderBoard
      studentData={studentData}
      error={errorFetchingTagLeaderBoard}
      isLoadingStudents={isLoadingStudents}
      isFetching={isFetching}
      isClickable={false}
      campaignId={data?.id || '0'}
      groupId={data?.group_id?? 0}
      visible={isLeaderBoardVisible}
      onClose={handleLeaderBoardClose}
      isMobile={false} // Or pass a prop to determine if the device is mobile
    />}
    </>
  );
};

export default Banner;
