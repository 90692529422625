import React, { useEffect, useState } from 'react';
import '../styles/AddProduct.scss';  // Update with relevant styles
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import { useGetInventoryCatalogsQuery, useEditCatalogMutation, useGetInventoryProductsQuery, useGetGroupsNamesQuery } from '../services/admin/inventoryApi';  // Update with correct API hooks
import { Catalog, Group, Item } from '../types.ts';  // Update with correct types
import { Form, Input, Button, Typography, Drawer, List, Checkbox, Modal, Radio, Row, Spin, message } from 'antd';
import { DownOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const { TextArea } = Input;
const { Title } = Typography;

const EditCatalog: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: catalogsData, error: catalogsError, isLoading: catalogsLoading } = useGetInventoryCatalogsQuery({ skip: 0, limit: 1000 });
  const { data: productsData, error: productsError, isLoading: productsLoading } = useGetInventoryProductsQuery({ skip: 0, limit: 1000, catalog_id: parseInt(id || '0') });
  const [updateCatalog, { isLoading: isUpdating, isError, isSuccess }] = useEditCatalogMutation();
  const navigate = useNavigate();
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsNamesQuery({ skip: 0, limit: 1000 });
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const { control, handleSubmit, formState: { errors,isDirty }, setValue, getValues } = useForm({
    defaultValues: {
      name: '',
      blurb: '',
      price_schedules: {} as Record<number, number>,
      products: [] as Item[],
      groups: [] as Group[]

    }
  });
  const user = useSelector((state: RootState) => state.adminUser.user);
  const [isGroupDrawerVisible, setIsGroupDrawerVisible] = useState(false);
  const [isProductDrawerVisible, setisProductDrawerVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Item[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSettingNewPrice, setIsSettingNewPrice] = useState(false);
  const [oldPrice, setOldPrice] = useState<number | null>(null);
  const [newPrice, setNewPrice] = useState<number | null>(0);
  const [priceError, setPriceError] = useState<string | null>(null);

  useEffect(() => {
    if (catalogsData) {
      const catalog = catalogsData.find((item: Catalog) => item.id === parseInt(id || "0"));
      if (catalog) {
        setValue('name', catalog.name);
        setValue('blurb', catalog.blurb);
        setValue('price_schedules', catalog.price_schedules);
        setValue('products', catalog.products ?? []);
        setSelectedGroups(catalog?.groups || []);
        setSelectedProducts(catalog.products ?? []);
      }
    }
  }, [catalogsData, id, setValue]);

  const onSubmit = async (data: any) => {
    const updatedData = {
      ...data,
      price_schedules: Object.fromEntries(
        Object.entries(data.price_schedules).map(([key, value]) => [key, Math.round(value as number)])
      ),
      products: selectedProducts.map(product => product.id),
      groups : selectedGroups.map(group => group.id),
    };
    try {
      await updateCatalog({ id, ...updatedData }).unwrap();
      console.log('Catalog updated successfully:', updatedData);
      message.success('Your changes have been saved');
      // navigate(-1);
    } catch (error) {
      console.error('Failed to update catalog:', error);
    }
  };

  const handleProductDrawerOpen = () => {
    setisProductDrawerVisible(true);
  };

  const handleProductDrawerClose = () => {
    setisProductDrawerVisible(false);
  };
  const handleGroupDrawerOpen = () => {
    setIsGroupDrawerVisible(true);
  };

  const handleGroupDrawerClose = () => {
    setIsGroupDrawerVisible(false);
  };

  const handleProductToggle = (product: Item) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedSelectedProducts = prevSelectedProducts.some(selectedProduct => selectedProduct.id === product.id)
        ? prevSelectedProducts.filter(selectedProduct => selectedProduct.id !== product.id)
        : [...prevSelectedProducts, product];

      if (!prevSelectedProducts.some(selectedProduct => selectedProduct.id === product.id)) {
        setSelectedProductId(product.id);
        setOldPrice( product.cost / 100);
        setNewPrice(0);
        setIsSettingNewPrice(false);
        setIsModalVisible(true);
      } else {
        // Remove the price schedule for deselected product
        const updatedPriceSchedules = { ...getValues().price_schedules };
        delete updatedPriceSchedules[product.id];
        setValue('price_schedules', updatedPriceSchedules);
      }

      return updatedSelectedProducts;
    });
  };

  const handleProductClick = (product: Item) => {
    setSelectedProductId(product.id);
    setOldPrice( product.cost / 100);
    setNewPrice(0);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    console.log(newPrice)
    if (newPrice !== null && selectedProductId !== null) {
      if (oldPrice&&(newPrice < oldPrice)&&isSettingNewPrice) {
        setPriceError('New price must be greater than the base product price.');
        return;
      } 
      const oldValue = oldPrice ?? 0
      const updatedPriceSchedules = { ...getValues().price_schedules, [selectedProductId]:isSettingNewPrice? newPrice* 100:oldValue * 100 };
      setValue('price_schedules', updatedPriceSchedules);
    }else if(typeof newPrice !== 'number'&&isSettingNewPrice){
      setPriceError('please enter new price.');
      return;
    }else{
      const updatedPriceSchedules = { ...getValues().price_schedules };
      selectedProductId&&  delete updatedPriceSchedules[selectedProductId];
      setValue('price_schedules', updatedPriceSchedules);
    }
    
    setIsModalVisible(false);
    setNewPrice(0);
    setPriceError(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsSettingNewPrice(false);
    setNewPrice(0);
    setPriceError(null);
  };
  const handleGroupSelect = (group: Group) => {
    setSelectedGroups(prevGroups => {
      if (prevGroups.some(g => g.id === group.id)) {
        return prevGroups.filter(g => g.id !== group.id);
      }
      return [...prevGroups, group];
    });
  };
  const filteredGroups = groupsData?.data?.filter(group => group.is_rep_group) ?? [];

  return (
    <div className="add-product-container">
      <Title level={2}>Edit Catalog</Title>
      {catalogsLoading || productsLoading ? (
        <p style={{
          marginLeft:20
        }}><Spin tip="Loading..." /></p>
      ) : catalogsError || productsError ? (
        <p>Failed to load data.</p>
      ) : (
        <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-product-form" disabled={user?.scope==="rep"}>
   <div style={{maxWidth: 800}}>
          <Form.Item
            label="Name"
            validateStatus={errors.name ? 'error' : ''}
            help={errors.name ? errors.name.message : ''}

          >
            <Controller
              name="name"
              control={control}
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <Input type="text" {...field} placeholder='Enter Catalog Name' />
              )}
            />
          </Form.Item>

          <Form.Item
            label="Blurb"
            validateStatus={errors.blurb ? 'error' : ''}
            help={errors.blurb ? errors.blurb.message : ''}
          >
            <Controller
              name="blurb"
              control={control}
              rules={{ required: 'Blurb is required' }}
              render={({ field }) => (
                <TextArea {...field} rows={8}  placeholder='Enter description here...'  />
              )}
            />
          </Form.Item>

          <Form.Item
            label="Products"
            validateStatus={errors.products ? 'error' : ''}
            help={errors.products ? errors.products.message : ''}
          >
            <Input
              value={'Select Products'}
              onClick={handleProductDrawerOpen}
              readOnly
              style={{ marginTop: '10px' }}
              addonAfter={<DownOutlined  onClick={handleProductDrawerOpen}/>}
            />
            <List
              dataSource={selectedProducts}
              renderItem={(product: Item) => (
                <List.Item key={product.id} onClick={()=>user?.scope!=="rep"&&handleProductClick(product)} style={{cursor:'pointer',width:"100%" , display:"block"}}>
                  <div style={{
                    display:"flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}>
                  {product.internal_name} ${getValues().price_schedules[product.id] ? (getValues().price_schedules[product.id] / 100).toFixed(2) : (product.cost / 100).toFixed(2)}
                  <EditOutlined style={{
                    alignSelf:"end",
                    color: "#1890FF"
                  }} />
                  </div>
                </List.Item>
              )}
            />
          </Form.Item>
          <Form.Item
            label="Fundraising Company"
            validateStatus={errors.groups ? 'error' : ''}
            help={errors.groups ? errors.groups.message : ''}
          >
            <Input
              value={'Select Organizations'}
              readOnly
              onClick={handleGroupDrawerOpen}
              style={{ marginTop: '10px' }}
              addonAfter={<DownOutlined  onClick={handleGroupDrawerOpen}/>}
            />
          <List
            dataSource={selectedGroups}
            renderItem={group => (
              <List.Item key={group.id} style={{cursor:'pointer',width:"100%" , display:"block"}}>
                  <div style={{
                    display:"flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}>
                {group.name}
                <EditOutlined style={{
                    alignSelf:"end",
                    color: "#1890FF"
                  }} />
                  </div>
              </List.Item>
            )}
          />
        </Form.Item>

        </div>
        <Row justify={'end'}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={catalogsLoading || isUpdating} >Save Changes</Button>
          </Form.Item>
          </Row>
          {isError && <p>Failed to update catalog. Please try again.</p>}
          {isSuccess && <p>Catalog updated successfully!</p>}
        </Form>
      )}

      <Drawer
        title="Select Products"
        width={400}
        onClose={handleProductDrawerClose}
        visible={isProductDrawerVisible}
      >
        <List
          dataSource={productsData ?? []}
          renderItem={(product: Item) => (
            <List.Item key={product.id}>
              <Checkbox
                checked={selectedProducts.map((product) => product.id).includes(product.id)}
                onChange={() => handleProductToggle(product)}
              >
                {product.internal_name}
              </Checkbox>
              
            </List.Item>
          )}
        />
      </Drawer>
      <Drawer
        title="Select Organizations"
        width={400}
        loading={isLoadingGroups}
        onClose={handleGroupDrawerClose}
        visible={isGroupDrawerVisible}
      >
        <List
          dataSource={filteredGroups ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Checkbox
                checked={selectedGroups.some(g => g.id === group.id)}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Checkbox>
            </List.Item>
          )}
        />
      </Drawer>
      <Modal
 title={`Set Product Price`}
 onCancel={handleModalCancel}
  visible={isModalVisible}
  zIndex={2000} // Ensure the modal is on top of the drawer
  footer={()=>  <Row justify={'center'}><Button type="primary" htmlType="submit" style={{width:200}} onClick={handleModalOk}>OK</Button></Row>}
>
  <Title level={3} >
    <div style={{
    display:"flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }}>
   <div>
   {productsData?.find(product => product.id === selectedProductId)?.internal_name ?? "No internal name is given"}</div>  
   <div>
   ${oldPrice?.toFixed(2)}
   </div>
  </div></Title>
  <Radio.Group
    onChange={(e) => {
      e.target.value === 'new' ? setIsSettingNewPrice(true) : setIsSettingNewPrice(false);
      setPriceError(null);
      setNewPrice(0);
    }}
    value={isSettingNewPrice ? 'new' : 'old'}
    style={{
      width:"100%"
    }}
  >
   <Radio value="old">Keep Base Product Price</Radio>
   <div style={{
    display:'flex',
    flexDirection:'row',
    justifyContent: "space-between"
   }}>
    <div>
    <Radio value="new">
    <p
   style={{
    textAlign: "initial"
   }}
   >
    
    Set New Price</p>
    </Radio>
    </div>
    <div>
    {isSettingNewPrice && (
    <CurrencyInput
    className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined currencyInputCatalog'
     placeholder="Please enter Goal"
     defaultValue={0}
     decimalsLimit={2}
     onValueChange={(value) => {
       const centsValue = value ? parseFloat(value) : 0;
       
       setNewPrice(centsValue);
       setPriceError(null);
     }}
     prefix="$"
     style={{ marginTop: '10px' }}
   />
  )}
    </div>
   </div>
  
   
  </Radio.Group>
  
  {priceError && <p style={{ color: 'red' }}>{priceError}</p>}
</Modal>
    </div>
  );
};

export default EditCatalog;
