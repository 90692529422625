import React, { useEffect, useState } from "react";
import { Drawer, Form, Input, Flex, Spin, Tag, Select, Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import usePagination from "../hooks/usePagination";
import { useGetUsersQuery } from "../services/admin/inventoryApi";

const { Option } = Select;

interface OrderDetailsDrawerProps {
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
  selectedRowData: any;
  isLoadingProduct: boolean;
  isErrorProduct: boolean;
  dataLoadingProduct: any;
  formatCurrency: (value: number) => string;
  setSelectedUser: (value: string) => void;
  onSubmit: () => void;
  isLoadingTagUser: boolean;
}
interface UserOptionsProps { 
    userId:number,
  
    fullName: string;
    email: string;
  
  }

const OrderDetailsDrawer: React.FC<OrderDetailsDrawerProps> = ({
  isDrawerOpen,
  handleCloseDrawer,
  selectedRowData,
  isLoadingProduct,
  isErrorProduct,
  dataLoadingProduct,
  formatCurrency,
  setSelectedUser,
  onSubmit,
  isLoadingTagUser,
}) => {
    const { currentPage: allUserCurrentPage, pageSize: allUsersPageSize, onPageChange: onAllUsersPageChange } = usePagination(0, 5);
    const [userOptions, setUserOptions] = useState<UserOptionsProps[]>([]);
    const { data: usersData, error: usersError, isLoading: usersLoading, isFetching: usersFetching } = useGetUsersQuery({
        skip: 0,
        limit: 1000,
        group_id_filter:selectedRowData?.campaign?.group_id ?selectedRowData?.campaign?.group_id:null,
      },{skip:!selectedRowData?.campaign?.group_id});
      useEffect(() => {
        // Assuming the user data comes from another API or part of the current data
        if (usersData && usersData.data) {
          const users = usersData.data.map(user => ({
            userId: user.id,
            fullName: user.full_name,
            email: user.email,
          }));
          setUserOptions(users);
        }
      }, [usersData]);
      const handleSearch = (value: string) => {
        if (usersData && usersData.data) {
          const users = usersData.data.map(user => ({
            userId: user.id,
            fullName: user.full_name,
            email: user.email,
          }));
          const filteredOptions = users.filter(option =>
            option.fullName.toLowerCase().includes(value.toLowerCase()) ||
            option.email.toLowerCase().includes(value.toLowerCase())
          );
          setUserOptions(filteredOptions);
          
        }
      
        
        
      };
  return (
    <Drawer open={isDrawerOpen} onClose={handleCloseDrawer} title="Order Details" width={500}>
      {selectedRowData && (
        <Spin spinning={isLoadingProduct}>
          <Form layout="vertical">
            <Flex justify="space-between" align="center">
              <Form.Item label="Full Name">
                <Input value={`${selectedRowData.recipient_first_name} ${selectedRowData.recipient_last_name}`} readOnly />
              </Form.Item>
              <Form.Item label="Email">
                <Input value={selectedRowData.email} readOnly />
              </Form.Item>
            </Flex>
            <Form.Item label="Address 1">
              <Input value={selectedRowData.recipient_address_1} readOnly />
            </Form.Item>
            <Form.Item label="Address 2">
              <Input value={selectedRowData.recipient_address_2} readOnly />
            </Form.Item>
            <Flex justify="space-between" align="center">
              <Form.Item label="City">
                <Input value={selectedRowData.recipient_address_city} readOnly />
              </Form.Item>
              <Form.Item label="State">
                <Input value={selectedRowData.recipient_address_state} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
              <Form.Item label="ZIP">
                <Input value={selectedRowData.recipient_address_zip} readOnly />
              </Form.Item>
              <Form.Item label="Phone">
                <Input value={selectedRowData.recipient_phone} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
              <Form.Item label="Seller Name FreeForm">
                <Input value={selectedRowData.student_name_freeform} readOnly />
              </Form.Item>
              <Form.Item label="Donation Amount">
                <Input value={formatCurrency(selectedRowData.amount_donation)} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
              <Form.Item label="Products">
                {!isErrorProduct &&
                  (Object.values(dataLoadingProduct ?? {}) as string[]).map((value, index) => <Tag key={index}>{value}</Tag>)}
              </Form.Item>
              <Form.Item label="Quote Number">
                <Input value={selectedRowData.quote_number} readOnly />
              </Form.Item>
            </Flex>
            <Flex justify="space-between" align="center">
              <Form.Item label="Campaign ID">
                <Input value={selectedRowData.campaign_id} readOnly />
              </Form.Item>
              {!(selectedRowData.student_name_freeform || !selectedRowData.user_id) && (
                <Form.Item label="Seller ID">
                  <Input value={selectedRowData.user_id} readOnly />
                </Form.Item>
              )}
            </Flex>

            {(selectedRowData.student_name_freeform || !selectedRowData.user_id) && (
              <Form.Item label="Seller ID">
                <Flex justify="space-between" align="center">
                  <Select
                    showSearch
                    loading={true}
                    placeholder="Select a user"
                    optionFilterProp="children"
                    onSearch={handleSearch}
                    onChange={(value) => setSelectedUser(value)}
                    style={{ width: "280px", height: "53px", borderRadius: 2 }}
                  >
                    {userOptions.map((option) => (
                      <Option key={option.userId} value={option.userId}>
                        {option.fullName} ({option.email})
                      </Option>
                    ))}
                  </Select>
                  <Button type="primary" onClick={onSubmit} loading={isLoadingTagUser}>
                    Tag User
                  </Button>
                </Flex>
              </Form.Item>
            )}

            <Form.Item label="Created At">
              <Input value={new Date(selectedRowData.created_at).toLocaleString()} readOnly />
            </Form.Item>
            <Form.Item label="Message">
              <TextArea
                value={selectedRowData.message}
                defaultValue={selectedRowData.message}
                readOnly
                autoSize={{ minRows: 3, maxRows: 5 }}
                style={{ wordWrap: "break-word", whiteSpace: "normal" }}
              />
            </Form.Item>
            <Form.Item label="Product Amount">
              <Input value={formatCurrency(selectedRowData.amount_product)} readOnly />
            </Form.Item>
          </Form>
        </Spin>
      )}
    </Drawer>
  );
};

export default OrderDetailsDrawer;
