import React, { useEffect, useState } from 'react';
import { Modal, Table, Pagination, Spin, Card, Image, Button, Typography } from 'antd';
import { useGetCampaignForUserQuery, useGetLeaderboardForPublicCampaignBasedOnTagsQuery, useGetLeaderboardForPublicCampaignQuery } from '../services/campaign/index';
import { Campaign } from '../types.ts';
import { formatPrice } from '../utils';
import myImage from '../assets/images/leaderboard.png';
import "../styles/PublicLeaderBoard.scss"
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCampaignId, setUserId } from '../slices/admin/adminUserSlice';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import Meta from 'antd/es/card/Meta';
import Title from 'antd/es/typography/Title';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';


interface PublicLeaderBoardProps {
  campaignId: string;
groupId: number;
studentData?: Campaign[];
error?: FetchBaseQueryError | SerializedError | undefined;
isLoadingStudents?: boolean;
isFetching?: boolean;
}

const PublicTagLeaderBoard: React.FC<PublicLeaderBoardProps> = ({ campaignId,groupId,studentData:data,error, isLoadingStudents:isLoadingTags,isFetching:isFetchingTags=false}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Text } = Typography;
 ;
  
  const { user_id } = useParams<{ id: string, campaign_id: string, user_id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 1000;

  const { data: campaignData, error: campaignError, isLoading: campaignIsLoading } = useGetCampaignForUserQuery(campaignId || '0', {
    skip: !campaignId,
  });
  
  // Fetch leaderboard data based on the fetched campaign ID
  const { data: studentData = [], error: errorFetchingTagLeaderBoard, isLoading: isLoadingStudents ,isFetching} = useGetLeaderboardForPublicCampaignBasedOnTagsQuery({
    campaignId: campaignId ?? '0',
    groupId: groupId ?? 0,
    skip: 0,
    limit: 10000,
  },{skip:!data});
  
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleNameClick = (userId: string, name: string) => {
    dispatch(setUserId(userId));
    dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
    navigate(`/dashboard/users`, { state: { name } })
  };

  const handleOrderCountClick = (userId: string) => {
    dispatch(setUserId(userId));
    dispatch(setCampaignId(campaignId));
    dispatch(setActiveTab(`tab7` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
    navigate(`/dashboard/orders`)
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: isDesktop ? '33%' : '50px',
      render: (text: string, record: any) => (
        <>
      <h5  style={{ cursor:"auto" }}>
    {text}
  </h5>
  
  </>
      ),
    },
    {
      title: 'Order Count',
      dataIndex: 'products_sold',
      key: 'products_sold',
      width: isDesktop ? '33%' : '50px',
      render: (text: number, record: any) => (
        <>
        
        <h5  style={{ cursor: 'auto' }}>
        {text}
      </h5>


</>
      ),
    },
    {
      title: 'Total Raised',
      dataIndex: 'amount_total_sold',
      key: 'amount_total_sold',
      render: (number: number) => (
        <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>${formatPrice(number)}</p>
      ),
      width: isDesktop ? '33%' : '50px', // Adjust column width for mobile
    },
  ];
if(data)
  return (
   
      <Spin spinning={isLoadingTags||isFetchingTags}>
        {!isLoadingTags&&!isFetchingTags&&data.length>0? <Table
          columns={columns}
          dataSource={data || []}
          // @ts-ignore
          rowClassName={(record, index) => (String(record.user_id) === user_id ? 'first-row-highlight' : '')} // Apply class to the first row
          pagination={false}
          scroll={{ x: !isDesktop ? '100vw' : undefined }} // Enable horizontal scroll
        />: <Card
          
        style={{ width: "45%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
        cover={<Image alt="example" src={myImage} preview={false}/>}
      >
        <Meta title={<Title level={2} style={{
          textAlign: "center",
          marginBottom: "0px"
        }}>No Data is Shown yet</Title>} description={
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px"
        }}>
<Text style={{
color: "#595959",
fontSize: 16,
fontWeight: 400,
}}>As soon as the order is placed the data will be shown.
</Text>
        
        </div>
        }
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
        />
        
      </Card>}
       
      </Spin>
 
  );
  if(studentData)
  return (
   
    <Spin spinning={isLoadingStudents||isFetching}>
      {!isLoadingStudents&&!isFetching&&studentData.length>0? <Table
        columns={columns}
        dataSource={studentData || []}
        // @ts-ignore
        rowClassName={(record, index) => (String(record.user_id) === user_id ? 'first-row-highlight' : '')} // Apply class to the first row
        pagination={false}
        scroll={{ x: !isDesktop ? '100vw' : undefined }} // Enable horizontal scroll
      />: <Card
        
      style={{ width: "45%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
      cover={<Image alt="example" src={myImage} preview={false}/>}
    >
      <Meta title={<Title level={2} style={{
        textAlign: "center",
        marginBottom: "0px"
      }}>No Data is Shown yet</Title>} description={
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px"
      }}>
<Text style={{
color: "#595959",
fontSize: 16,
fontWeight: 400,
}}>As soon as the order is placed the data will be shown.
</Text>
      
      </div>
      }
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
      />
      
    </Card>}
     
    </Spin>

);
return (
  <div>
    {/* Your Component UI here */}
    <h2>Tag Leaderboard</h2>
    {/* Example: Render a table */}
    <Table dataSource={data} />
  </div>
);
};

export default PublicTagLeaderBoard;
