import React, { useEffect, useState } from 'react';
import { Modal, Table, Pagination, Spin, Card, Image, Button, Typography, Tabs, TabsProps } from 'antd';
import { useGetCampaignForUserQuery, useGetLeaderboardForPublicCampaignQuery } from '../services/campaign/index';
import { Campaign } from '../types.ts';
import { formatPrice } from '../utils';
import myImage from '../assets/images/leaderboard.png';
import "../styles/PublicLeaderBoard.scss"
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCampaignId, setUserId } from '../slices/admin/adminUserSlice';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import Meta from 'antd/es/card/Meta';
import Title from 'antd/es/typography/Title';
import { RootState } from '../store';

import PublicTagLeaderBoard from './PublicTagLeaderBoard';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

interface PublicLeaderBoardProps {
  campaignId: string;
  visible: boolean;
  onClose: () => void;
  isMobile: boolean;
  isClickable?:boolean;
  groupId: number;
  studentData?: Campaign[];
  error?: FetchBaseQueryError | SerializedError | undefined;
  isLoadingStudents?: boolean;
  isFetching?: boolean;

}

const PublicLeaderBoard: React.FC<PublicLeaderBoardProps> = ({ campaignId, visible, onClose, isMobile ,isClickable, groupId,studentData:data,error, isLoadingStudents: isloadingTagsLeaderBoard,isFetching:isFetchingTags}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Text } = Typography;
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const user = useSelector((state: RootState) => state.adminUser.user);
  const { user_id } = useParams<{ id: string, campaign_id: string, user_id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10000;

  const { data: campaignData, error: campaignError, isLoading: campaignIsLoading,isFetching:campaignIsFetching } = useGetCampaignForUserQuery(campaignId || '0', {
    skip: !campaignId || campaignId ==="0",
  });
  
  // Fetch leaderboard data based on the fetched campaign ID
  const { data: studentData = [], isLoading: isLoadingStudents ,isFetching} = useGetLeaderboardForPublicCampaignQuery({
    campaignId: campaignData?.id ?? '0',
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleNameClick = (userId: string, name: string) => {
    dispatch(setUserId(userId));
    dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
    navigate(`/dashboard/users`, { state: { name } })
  };

  const handleOrderCountClick = (userId: string) => {
    dispatch(setUserId(userId));
    dispatch(setCampaignId(campaignId));
    dispatch(setActiveTab(`tab7` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
    navigate(`/dashboard/orders`)
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: isDesktop ? '33%' : '50px',
      render: (text: string, record: any) => (
        <>
      {isClickable? <a onClick={() =>isClickable&& handleNameClick(record.user_id, record.name)} style={{ cursor: 'pointer' }}>
      {text}
    </a>:<h5 onClick={() =>isClickable&& handleNameClick(record.user_id, record.name)} style={{ cursor:isClickable? 'pointer':"auto" }}>
    {text}
  </h5>
  } 
  </>
      ),
    },
    {
      title: 'Order Count',
      dataIndex: 'products_sold',
      key: 'products_sold',
      width: isDesktop ? '33%' : '50px',
      render: (text: number, record: any) => (
        <>
        {isClickable?
        <a onClick={() =>isClickable&&  handleOrderCountClick(record.user_id)} style={{ cursor: 'pointer' }}>
          {text}
        </a>:
        <h5 onClick={() =>isClickable&&  handleOrderCountClick(record.user_id)} style={{ cursor: 'auto' }}>
        {text}
      </h5>

} 
</>
      ),
    },
    {
      title: 'Total Raised',
      dataIndex: 'amount_total_sold',
      key: 'amount_total_sold',
      render: (number: number) => (
        <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>${formatPrice(number)}</p>
      ),
      width: isDesktop ? '33%' : '50px', // Adjust column width for mobile
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'User Leader Board',
      children: <> <Spin spinning={isLoadingStudents||isFetching||campaignIsLoading||campaignIsFetching||!campaignId||campaignId==="0"}>
      {!isLoadingStudents&&!isFetching&&studentData.length>0? <Table
        columns={columns}
        dataSource={studentData || []}
        // @ts-ignore
        rowClassName={(record, index) => (String(record.user_id) === user_id ? 'first-row-highlight' : '')} // Apply class to the first row
        pagination={false}
        scroll={{ x: !isDesktop ? '100vw' : undefined }} // Enable horizontal scroll
      />: <Card
        
      style={{ width: "45%", backgroundColor:"transparent",alignSelf:"center",margin:"0px auto" }}
      cover={<Image alt="example" src={myImage} preview={false}/>}
    >
      <Meta title={<Title level={2} style={{
        textAlign: "center",
        marginBottom: "0px"
      }}>No Data is Shown yet</Title>} description={
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px"
      }}>
<Text style={{
color: "#595959",
fontSize: 16,
fontWeight: 400,
}}>As soon as the order is placed the data will be shown.
</Text>
      
      </div>
      }
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
      />
      
    </Card>}
     
    </Spin></>,
    },
    ...(data === undefined ||data&&data.length > 0 ? [  
    {
      key: '2',
      label: 'Tag Leader Board',
      children: <PublicTagLeaderBoard 
        studentData={data}
        error={error}
        isLoadingStudents={isLoadingStudents}
        isFetching={isFetching}
        campaignId={campaignId || '0'}
        groupId={groupId || 0}
      />,
    }
  ] : []),
 
  ];
  return (
    <Modal
      title="Leader Board"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={isDesktop ? '60%' : '100%'} // Full width for mobile
      style={{
        height: 500
      }}
    >
      <Tabs defaultActiveKey="1" items={items}  />
     
    </Modal>
  );
};

export default PublicLeaderBoard;
