import React from 'react';
import { Table, Flex } from 'antd';
import { Group } from '../types.ts'; // Ensure correct import path
import Title from 'antd/es/typography/Title';
import { useNavigate } from 'react-router-dom';
import '../styles/CatalogsTable.scss';

interface GroupsTableProps {
  associatedGroups: Group[];
  loading: boolean;
}
const RepGroupTable: React.FC<GroupsTableProps> = ({ associatedGroups,loading }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'blurb',
      key: 'blurb',
      sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
    },
  ];

 const  handleRowClick=(record:Group)=>{
    navigate(`/edit-groups/${record.id}`)
  }
  
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={associatedGroups || []}
        pagination={false}
        rowKey="id"
        onRow={(record) => ({
            onClick: () => handleRowClick(record),
        })}
        title={() => (
          <Flex justify="space-between" align="center" className="flexRow">
            <Title level={2}>Organization List </Title>
          </Flex>
        )}
        rowClassName="clickable-row"
      />
    </>
  );
};

export default RepGroupTable;
