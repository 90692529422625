// MainLayout.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Button, Drawer, MenuProps, Flex, Modal, Spin } from 'antd';
import { FlagOutlined, MenuOutlined, NotificationOutlined, PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { FlagFilled, HomeFilled, LoginOutlined, ProfileFilled } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails, signOut } from './slices/admin/adminUserSlice';
import logo from './assets/images/logo.png';
import { useLazyGetUserQuery } from './services/admin/adminAuth';
import { RootState } from './store';
import { setActiveTab } from './slices/admin/activeTabSlice';
import {

  ShoppingOutlined,
  AppstoreOutlined,
  UsergroupAddOutlined,
  BulbOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import './styles/Dashboard.scss';
import usePagination from './hooks/usePagination';
import { useGetInventoryCatalogsQuery } from './services/admin/inventoryApi';

const { Header, Content, Sider } = Layout;

const scopes = ['internal_admin', 'rep_main', 'internal_support', 'rep_sub', 'sponsor', 'sponsor_sub', 'rep'];

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentPage, pageSize, onPageChange } = usePagination();
  const [getUser, { data: user, isLoading: isLoadingUser, isFetching: isFetchingUser  }] = useLazyGetUserQuery();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, error, isLoading } = useGetInventoryCatalogsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  const handleOk =()=>{
    dispatch(signOut());
        navigate('/signin');
  }
  const handleCancel=()=>{
    setIsModalOpen(false)
  }
  useEffect(() => {
    if(!user){
      getUser();
    }
 
    dispatch(
      setUserDetails(user??{})
    );
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getUser,user]);

  const handleMenuClick = useCallback(
    (e: any) => {
      if (e.key === '9') {
        setIsModalOpen(true)
      } else {
        if (!isDesktop) {
          setDrawerVisible(false);
        }
        dispatch(setActiveTab(`${e.key}` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
        if(e.key==="tab6"){
          navigate(`/dashboard/users`)
        }else if(e.key==="tab7"){
          navigate(`/dashboard/orders`)
        }else if(e.key==="tab1"){
          navigate(`/dashboard/products`)
        }else if(e.key==="tab2"){
          navigate(`/dashboard/catalogs`)
        }else if(e.key==="tab3"){
          navigate(`/dashboard/groups`)
        }else if(e.key==="tab4"){
          navigate(`/dashboard/campaigns`)
        }else if(e.key==="tab8"){
          navigate(`/dashboard/reports`)
        }else if(e.key==="tab5"){
          navigate(`/dashboard/user-profile`)
        }else if(e.key==="tab11"){
          navigate(`/dashboard/user-dashboard`)
        }else if(e.key==="tab9"){
          navigate(`/dashboard/user-campaigns`)
        }else{
          navigate(`/dashboard`)
        }
        
      }
    },
    [dispatch, navigate, isDesktop]
  );
useEffect(()=>{
  if(location.pathname==="/dashboard/users"){
    dispatch(setActiveTab(`tab6` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/orders"){
    dispatch(setActiveTab(`tab7` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/products"){
    dispatch(setActiveTab(`tab1` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/catalogs"){
    dispatch(setActiveTab(`tab2` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/groups"){
    dispatch(setActiveTab(`tab3` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname.includes("edit-group")){
    dispatch(setActiveTab(`tab3` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/campaigns"){
    dispatch(setActiveTab(`tab4` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname.includes("edit-campaign")){
    dispatch(setActiveTab(`tab4` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/reports"){
    dispatch(setActiveTab(`tab8` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/user-profile"){
    dispatch(setActiveTab(`tab5` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/user-dashboard"){
    dispatch(setActiveTab(`tab11` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }else if(location.pathname==="/dashboard/user-campaigns"){
    dispatch(setActiveTab(`tab9` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
  }
},[
location.pathname
])
  const items2: MenuProps['items'] = [
    {
      key: 'sub1',
      icon: React.createElement(NotificationOutlined),
      label: 'Manage Admin',
      children: [
        ...(user?.scope === 'internal_admin'
          ? [
              { key: 'tab1', label: 'Products', icon: React.createElement(ShoppingOutlined) },
            ]
          : []), // Only show Products and Catalogs if scope is internal_admin
          ...((user?.scope === 'internal_admin')||(user?.scope === 'rep'&&(data?.length??0>0))
          ? [
              { key: 'tab2', label: 'Catalogs', icon: React.createElement(AppstoreOutlined) },
            ]
          : []), // Only show Products and Catalogs if scope is internal_admin
         
        { key: 'tab3', label: 'Organizations', icon: React.createElement(UsergroupAddOutlined) },
        { key: 'tab4', label: 'Campaigns', icon: React.createElement(BulbOutlined) },
        { key: 'tab6', label: 'Users', icon: React.createElement(UserOutlined) },
        { key: 'tab7', label: 'Sales', icon: React.createElement(ShoppingCartOutlined) },
        // { key: 'tab8', label: 'Reports', icon: React.createElement(BarChartOutlined) },
      ],
    },
    {
      key: 'sub3',
      icon: React.createElement(UserOutlined),
      label: 'My Account',
      children: [
        { key: 'tab11', label: 'Dashboard', icon: React.createElement(  PieChartOutlined) },
        { key: 'tab5', label: 'Profile', icon: React.createElement(UserOutlined) },
       
        { key: 'tab9', label: 'Campaigns', icon: React.createElement(FlagOutlined) },
        { key: '9', label: 'Sign Out', icon: React.createElement(LoginOutlined) },
      ],
    },
  ];

  return (
    <Layout className={"dashboard"}>

<Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            mode="inline"
            
            selectedKeys={[activeTab]}
            defaultOpenKeys={['sub1', 'sub3']}
            style={{ height: '100%', borderRight: 0 }}
            items={user && scopes.includes(user?.scope) ? items2 : items2.filter(item => item?.key === 'sub3')}
            onClick={handleMenuClick}
          />
        </Drawer>
      {isDesktop &&(
        <Sider width={200} className="sider">
            <div style={{ padding: '16px', textAlign: 'center' , backgroundColor: "white"}}>
          <img
            src={logo} alt="Company Logo"
            style={{ width: '100%', maxWidth: '120px' }}
          />
        </div>
          <Menu
            mode="inline"
            selectedKeys={[activeTab]}
            defaultSelectedKeys={[activeTab]}
            defaultOpenKeys={['sub1', 'sub3']}
            style={{ height: '100%', borderRight: 0 }}
            items={user && scopes.includes(user?.scope) ? items2 : items2.filter(item => item?.key === 'sub3')}
            onClick={handleMenuClick}
          />
        </Sider>
      )}
    {isLoadingUser||isFetchingUser?<Spin spinning={isLoadingUser} /> : <Layout>
        <Header className={isDesktop?"dashboard-header":"dashboard-header-mobile"}>
            {!isDesktop && (
               <Flex justify="space-between" align="center">
               <div style={{  backgroundColor: "white", width:"100%"}}>
                       <img
                         src={logo} alt="Company Logo"
                         style={{  maxWidth: '100px',display:"flex",alignSelf:"center" }}
                       />
                     </div>
                     <Button
                           style={{
                             backgroundColor:"#fff",
                             boxShadow: "none",
                             marginRight: '16px',
                             color: "black"
                           }}
                             className="menu-toggle"
                             type="primary"
                             icon={<MenuOutlined size={20} />}
                             onClick={() => setDrawerVisible(true)}
                           />
                           </Flex>
            )}
        </Header>
        <Content className={isDesktop?"content":"mainLayoutContent"}>
      {isLoadingUser||isFetchingUser?<Spin spinning={isLoadingUser} /> :  <Outlet />}
        <Modal title="Sign-Out from account
Confirmation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure you would like to sign out?</p>

      </Modal>
        </Content>
      </Layout>}
    </Layout>
  );
};

export default MainLayout;
