import React, { useEffect, useState } from 'react';
import { Spin, message, Button, Card, Flex, Grid } from 'antd';
import {
  useInviteUsersMutation,
  useGetInviteStudentsQuery,
  useGetCampaignpByIdQuery,
} from '../services/admin/inventoryApi';
import { EyeOutlined, UploadOutlined } from '@ant-design/icons';

import ContactsModal from './ContactsModal';
import LeaderBoardModal from './LeaderBoardModal';
// @ts-ignore
import { CSVLink } from 'react-csv';

import { Typography } from 'antd';
import { formatWithCommas } from '../utils';
import '../styles/StudentCampaign.scss';
import Title from 'antd/es/typography/Title';
import Meta from 'antd/es/card/Meta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandHoldingDollar,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import DashboardOrdersTable from './DashboardOrdersTable';
import ShareButtonStatic from './ShareButtonStatic';
import CustomCSVUploader from './CustomCSVUploader';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface ParsedData {
  email_address: string;
  name: string;
}
const { useBreakpoint } = Grid;
const { Paragraph } = Typography;

const CampaignDetails: React.FC<{ isMobile?: boolean }> = ({
  isMobile = false,
}) => {
  const screens = useBreakpoint();

  const location = useLocation();
  const { campaignId } = location.state || {};

  const { data: studentData, isLoading: isLoadingStudents } =
    useGetInviteStudentsQuery({
      campaignId: campaignId ? campaignId : '',
      skip: 0,
      limit: 10,
    });
  const user = useSelector((state: RootState) => state.adminUser.user);
  const { data, isLoading, error } = useGetCampaignpByIdQuery({
    campaign_id: parseInt(campaignId || '0'),
  });
  const [contactsModalVisible, setContactsModalVisible] = useState(false);
  const [leaderBoardModalVisible, setLeaderBoardModalVisible] = useState(false);

  const [selectedContactsCampaignId, setSelectedContactsCampaignId] = useState<
    string | null
  >(null);

  const [inviteUsers, { isLoading: isUploadingCSV }] = useInviteUsersMutation();
  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);

  const handleDataParsed = async (data: ParsedData[], id: string) => {
    console.log('Parsed Data:', data);
    await inviteUsers({ campaignId: id ?? '0', data });
    message.success('CSV file parsed successfully!');
  };

  const handleNavigateToCampaign = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL_FE}raise/${data?.campaign_link}/${user?.id}`,
      '_blank'
    );
  };

  const openContactsModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setContactsModalVisible(true);
  };

  const closeContactsModal = () => {
    setContactsModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  const openLeaderBoardModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setLeaderBoardModalVisible(true);
  };

  const closeLeaderBoardModal = () => {
    setLeaderBoardModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  return (
    <>
      <div style={{ marginTop: '60px' }}>
        <Spin spinning={isLoading}>
          <Flex
            justify="space-between"
            align={screens.md ? 'center' : 'normal'}
            vertical={screens.md ? false : true}
          >
            <Title level={screens.md ? 4 : 2}>Campaigns - {data?.title}</Title>
            <Button
              type="primary"
              className="button"
              onClick={handleNavigateToCampaign}
            >
              Open Store
            </Button>
          </Flex>

          <div className="campaign-detail">
            <div className="card-container">
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faSackDollar}
                          size={'2xl'}
                          color="#FFBB38"
                        />
                      </div>
                    </div>
                  }
                  title={`$${formatWithCommas(data?.me_amount)}`}
                  description="Raised by Me"
                />
              </Card>
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container purple">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faHandHoldingDollar}
                          size={'2xl'}
                          color="#396AFF"
                        />
                      </div>
                    </div>
                  }
                  title={data?.me_count ? data?.me_count : '0'}
                  description="Sold By Me"
                />
              </Card>
            </div>
          </div>
          <Title level={4}>Share On</Title>
          <ShareButtonStatic
            campaignId={campaignId ?? ''}
            userId={user?.id ?? 0}
            showEmail={false}
            schoolName={data?.title ?? ''}
            blurb={data?.blurb ?? ''}
          />

          <Title level={4}>Contacts</Title>
          <Card className="campaign-contacts">
            <Meta
              avatar={
                <Flex align="start" gap={10}>
                  <Meta
                    title={studentData?.count ? studentData?.count : '0'}
                    description="Count"
                  />
                  {!!data?.group.group_contacts_required && (
                    <Meta
                      title={
                        data?.group.group_contacts_required
                          ? data?.group.group_contacts_required
                          : '0'
                      }
                      description="Required Conatcts Count"
                    />
                  )}
                </Flex>
              }
              title={
                <>
                  <Button onClick={() => openContactsModal(campaignId)}>
                    <EyeOutlined />
                    View Contact
                  </Button>
                  <CustomCSVUploader
                    button={
                      <Button className="csv-button">
                        <UploadOutlined />
                        Upload CSV
                      </Button>
                    }
                    onDataParsed={(data) => handleDataParsed(data, campaignId)}
                    loading={isUploadingCSV}
                  />
                </>
              }
              description={
                <Paragraph style={{ marginBottom: 0, marginTop: 10 }}>
                  Not sure what to include?
                  <CSVLink
                    filename="Sample.csv"
                    data={`name,email
John Doe,jdoe@example.com`}
                  >
                    {' '}
                    Download our sample CSV
                  </CSVLink>
                </Paragraph>
              }
            />
          </Card>
        </Spin>
        <Title level={3}>Recent Sales</Title>
        <DashboardOrdersTable user_id={user?.id} campaign_id={campaignId} />
      </div>

      {selectedContactsCampaignId && (
        <ContactsModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId}
          visible={contactsModalVisible}
          onClose={closeContactsModal}
        />
      )}
      {leaderBoardModalVisible && (
        <LeaderBoardModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId ?? ''}
          visible={leaderBoardModalVisible}
          onClose={closeLeaderBoardModal}
        />
      )}
    </>
  );
};

export default CampaignDetails;
