import React, { useMemo } from 'react';
import { Modal, Spin, Table } from 'antd';
import { useGetReportSignupsQuery } from '../services/admin/inventoryApi';
import { User } from '../types.ts';
import { useDispatch } from 'react-redux';
import { setUserId } from '../slices/admin/adminUserSlice';
import { setActiveTab } from '../slices/admin/activeTabSlice';
import { useNavigate } from 'react-router-dom';

interface AccountsignupModalProps {
  visible: boolean;
  onClose: () => void;
  isMobile: boolean;
  userData: User[];
  userLoading: boolean;
  campaignId: string;
}

const UsersGroupModal: React.FC<AccountsignupModalProps> = ({
  visible,
  onClose,
  isMobile,
  userData,
  userLoading,
  campaignId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: invitesData, isLoading: invitesLoading } = useGetReportSignupsQuery({ campaignId, skip: 0, limit: 1000 });
console.log(invitesData,userData)
  const mergedData = useMemo(() => {
    // @ts-ignore
    const combined = [...(userData || []), ...(invitesData || [])];

    const uniqueUsers = new Map();
    combined.forEach(user => {
      // @ts-ignore
      const key = user?.email || user?.email_address;
      uniqueUsers.set(key, { ...user, selfSignup: userData.some(u => u.email === key) });
    });
    return Array.from(uniqueUsers.values());
  }, [userData, invitesData]);

  const handleNameClick = (userId: string, name: string) => {
    dispatch(setUserId(userId));
    dispatch(setActiveTab('tab6'));
    navigate('/dashboard/users', { state: { name } });
  };

  const columns = [
    { title: 'Self Signup', dataIndex: 'selfSignup', key: 'selfSignup', render: (text: boolean) => (text ? 'Yes' : 'No') },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      render: (text: string, record: any) => {
        const email = record?.email || record?.email_address;
        const truncatedEmail = email.length > 10 ? email.slice(0, 10) + '...' : email;
    
        return !record?.selfSignup ? (
          truncatedEmail
        ) : (
          <a onClick={() => handleNameClick(record.id, record.full_name)} style={{ cursor: 'pointer' }}>
            {truncatedEmail}
          </a>
        );
      },
    },
    { title: 'Account Created', dataIndex: 'account_created', key: 'account_created', render: (text: boolean,record:any) => (record.selfSignup ? 'Yes' : 'No') },
    { title: 'Name', dataIndex: 'full_name', key: 'full_name' },
    { title: 'Photo Uploaded', dataIndex: 'primary_image_url', key: 'primary_image_url', render: (text: boolean) => (text ? 'Yes' : 'No') },
    { title: 'First Sale', dataIndex: 'donations', key: 'donations' , render: (text: boolean) => (text)},
    { title: 'Invited Users Count', dataIndex: 'contacts', key: 'contacts' },
  ];

  return (
    <Modal title="Account Signups" visible={visible} onCancel={onClose} footer={null} width={800}>
      <Spin spinning={userLoading || invitesLoading}>
        <Table
          dataSource={mergedData}
          rowKey="email"
          columns={columns}
          scroll={{ x: isMobile ? '100vw' : undefined }}
        />
      </Spin>
    </Modal>
  );
};

export default UsersGroupModal;
